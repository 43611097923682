import paper from 'paper'
//import { saveAs } from 'file-saver'

class paper_canvas{
    constructor( nav_querry ){

        this.nav_querry = nav_querry;

        // variables

        let colors = {
            bg: null,
            origin: null,
            standard: null,
            fresh: null
        } // update with dedicated function

        let sizes = {
            origin: null,
            origin_x: null,
            origin_y: null,
            standard_radius: null,
            history_radius: null,
            winW_history: null
        } // update with dedicated function

        let dom_elements = {
            canvas: null
        }
        
        let canvas = {
            W: null,
            H: null,
            bg: null,
            bg_element: null
        }
        
        let helpers = {
            dot_index: 0,
            dot_old_index: 0,
            dot: {},
            dot_old: {}, 
            points: null,
            path: null,
            path_end: null,
            mypapers: [],
            dot_storage: {
                getData: function(){
                    var points = localStorage.getItem('canvas_points')
                    if(!points){
                        return []
                    }else{
                        return JSON.parse(points)
                    }
                },
                saveData: function(points){
                    localStorage.setItem('canvas_points',JSON.stringify(points))
                },
                add: function(x,y){
                    var points = this.getData()
                    points.push([x,y])
                    this.saveData(points)
                }
            }
        }

        // functions
        
        function underElement(elem,eX,eY){

            var rect = elem.getBoundingClientRect()
            var overlap = false
            if((eX > rect.left && eX < rect.right) && (eY > rect.top && eY < rect.bottom)) {overlap = true}
            return overlap

        }

        // update functions

        function updateSizes(){

            sizes.winW_history = window.innerWidth

            sizes.standard_radius = 7
            sizes.history_radius = 3

            // update sizes based on screensize
            let mq900 = window.matchMedia('screen and (min-width: 900px)')
            let mq1600 = window.matchMedia('screen and (min-width: 1600px)')
            
            if(mq1600.matches){ 

                sizes.standard_radius = 9
                sizes.history_radius = 5

            }else if(mq900.matches){ 

                sizes.standard_radius = 7
                sizes.history_radius = 3

            }
            
            // start
            sizes.origin = 125
            sizes.origin_x = document.querySelector("aside + div > div").getBoundingClientRect().left + (sizes.standard_radius)
            sizes.origin_y = (window.innerWidth / 100 * 9) + (sizes.standard_radius)
            
        }
        
        function updateColors(){

            // base version
            colors.bg = '#FAFAFA' // almost white (neutral)
            
            // colors.origin = '#B86125' // dark orange
            // colors.standard = 'rgba(237,188,11,1)' // yellow
            // colors.fresh = '#F68D2E' // bright orange

            // colors.origin = '#B45350' // red
            // colors.standard = '#F5DF73' // yellow
            // colors.fresh = '#F0A480' // orange

            //colors.origin = '#ae8867' // starting point: brown 
            colors.origin = '#d55a20' // starting point: red
            // colors.standard = '#dbe0c3' // path: light green
            colors.standard = '#c3cb9c' // path: darker green
            colors.fresh = '#7d8b39' // current dot: dark green

        }

        // ui functions

        function hide_content(){
            
            //document.getElementById('yayoi_kusama').classList.remove('hidden')
            //document.querySelector('body > aside').classList.toggle('hidden')
            //document.querySelector('body > aside + div').classList.toggle('hidden')
            // document.querySelector('#david_hockney').classList.toggle('hidden')

        }

        function hide_dots(){

            //document.getElementById('yayoi_kusama').classList.toggle('hidden')
            //document.querySelector('body > aside').classList.remove('hidden')
            //document.querySelector('body > aside + div').classList.remove('hidden')
            // document.querySelector('#david_hockney').classList.remove('hidden')

        }

        // dot functions

        function drawDot(x,y){

            //console.log("draw dot")

            helpers.path.originColor = colors.origin
            helpers.path.strokeColor = colors.standard
            helpers.path.newColor = colors.fresh
            helpers.path.add(new helpers.mypapers[0].Point((x),((y))))

            if(helpers.points.length == 0 && helpers.dot_index == 0){
                helpers.path.newColor = colors.origin
            }else if(helpers.points.length == 0 && helpers.dot_index > 1){
                helpers.dot[helpers.dot_index].fillColor = helpers.path.strokeColor
            }
            else if(helpers.points.length != 0 && helpers.dot_index != 0){
                helpers.dot[helpers.dot_index].fillColor = helpers.path.strokeColor
            }

            helpers.dot_index++
            helpers.dot[helpers.dot_index] = new helpers.mypapers[0].Path.Circle({

                center: new helpers.mypapers[0].Point((x),((y))), 
                radius: sizes.standard_radius, 
                fillColor: helpers.path.newColor

            })

        }

        function save_dots(){

            canvas.bg_element.fillColor = colors.bg // set current background-color
            canvas.bg_element.opacity = 1 // show backgroundcolor before saving file
            
            helpers.mypapers[0].view.draw()

            dom_elements.canvas.toBlob(function(blob){
                //saveAs(blob,"youdidthis.png")
            })

            canvas.bg_element.opacity = 0 // hide background from canvas again
            helpers.mypapers[0].view.draw()

        }

        // canvas functions
        
        function initPaper(){

            // set up paper canvas
            dom_elements.canvas = document.getElementById('yayoi_kusama').querySelector('canvas')
            canvas.W = document.querySelector('body').clientWidth,
            canvas.H = document.querySelector('body').clientHeight

            // init paper canvas
            helpers.mypapers[0] = new paper.PaperScope()
            helpers.mypapers[0].setup(dom_elements.canvas)
            helpers.mypapers[0].view.viewSize = new helpers.mypapers[0].Size(canvas.W, canvas.H)

            // background rectangle
            var point = new paper.Point(0,0),
                size = new helpers.mypapers[0].Size(canvas.W,canvas.H)

            canvas.bg = new helpers.mypapers[0].Rectangle(point, size)
            canvas.bg_element = new helpers.mypapers[0].Path.Rectangle(canvas.bg)

            canvas.bg_element.fillColor = colors.bg
            canvas.bg_element.opacity = 0// path (with dots)
            let i
            helpers.path = new helpers.mypapers[0].Path()
            helpers.path.strokeWidth = 1

            helpers.path.originColor = colors.origin
            helpers.path.strokeColor = colors.standard
            helpers.path.newColor = colors.fresh

            helpers.points = helpers.dot_storage.getData()
            if(helpers.points.length){

                let index, 
                    len

                for(index = 0, len = helpers.points.length; index < len; ++index){

                    var x = helpers.points[index][0],
                        y = helpers.points[index][1]

                    helpers.path.add(new helpers.mypapers[0].Point(x,y))

                    if(index == 0){

                        helpers.dot_old[helpers.dot_old_index] = new helpers.mypapers[0].Path.Circle({

                            center: new helpers.mypapers[0].Point(x,y), 
                            radius: sizes.standard_radius, 
                            fillColor: helpers.path.originColor

                        })

                        helpers.dot_old_index++

                    }else if(index == helpers.points.length-1){

                        helpers.path_end = new helpers.mypapers[0].Path.Circle({

                            center: new helpers.mypapers[0].Point(x,y), 
                            radius: sizes.standard_radius, 
                            fillColor: helpers.path.strokeColor

                        })

                    }else{

                        helpers.dot_old[helpers.dot_old_index] = new helpers.mypapers[0].Path.Circle({

                            center: new helpers.mypapers[0].Point(x,y), 
                            radius: sizes.history_radius, 
                            fillColor: helpers.path.strokeColor

                        })

                        helpers.dot_old_index++
                            
                    }

                }

            }else{

                helpers.dot_storage.add((sizes.origin_x),(sizes.origin_y))
                drawDot(sizes.origin_x,sizes.origin_y)
                
            }

            // draw paper element
            helpers.path.smooth()
            helpers.mypapers[0].view.draw()

            // functions

            function draw_dots(e){
                    
                helpers.dot_storage.add((e.pageX),(e.pageY))
                helpers.mypapers[0].activate()
                drawDot(e.pageX,e.pageY)
                helpers.path.smooth()
                helpers.mypapers[0].view.draw()

            }
            
            function delete_dots(){

                localStorage.removeItem('canvas_points')

                helpers.path.segments = []

                for(i = 0; i < helpers.dot_index; i++){ 
                    helpers.dot[i+1].remove() 
                }

                for(i = 0; i < helpers.dot_old_index; i++){ 
                    helpers.dot_old[i].remove() 
                }

                if(helpers.path_end)helpers.path_end.remove()
            
                helpers.dot_index = 0

                helpers.points = helpers.dot_storage.getData()
                helpers.dot_storage.add((sizes.origin_x),(sizes.origin_y))

                drawDot(sizes.origin_x, sizes.origin_y)
                helpers.mypapers[0].view.draw()
                
            }

            // bind events

            document.addEventListener("click",(e) =>{ 
                if( nav_querry ){
                    if( e.pageX > (document.querySelector("main").getBoundingClientRect().left) - (window.innerWidth / 100 * (3 * 1.618) / 2) && e.pageX < (window.innerWidth - (window.innerWidth / 100 * 3)) ){
                        draw_dots(e)     
                    }
                }else{
                    draw_dots(e) 
                }
            })

            window.addEventListener('resize',() =>{

                if(sizes.winW_history != window.innerWidth){

                    // kill the paper-element (if width changed)
                    hide_dots()
                    delete_dots()

                    // update variables
                    updateSizes()
                    updateColors()
                    updatePaper()

                    // kill the paper-element (if width changed)
                    delete_dots()
                    hide_dots()
                    
                }else{

                    // update variables
                    updateSizes()
                    updateColors()
                    updatePaper()

                }

            },{

                passive: true,

            })

        }

        function updatePaper(){

            let newW = document.querySelector('body').clientWidth,
                newH = document.querySelector('body').clientHeight

            helpers.mypapers[0].view.viewSize = new helpers.mypapers[0].Size(newW,newH)
            helpers.mypapers[0].view.draw()

        }

        // init

        updateSizes()
        updateColors()
        initPaper()

    }

}
export default paper_canvas
