// eslint-disable-next-line no-unused-vars

// polyfills
import './polyfills/forEach' // IE fix forEach-function
import './polyfills/scrollingElement' // (?) fix scrolling
import vh_fix from './polyfills/vh_fix' // Smartphone fix 100vh

// gsap
import { gsap } from "gsap"; 
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);

// paper.js
import paper_canvas from './components-lucasrees_com/paper_canvas';

// components
import scrolling from './components-lucasrees_com/scrolling';
import scrolltrigger from './components-lucasrees_com/scrolltrigger';
import interactive_title from './components-lucasrees_com/interactive_title';
import activity_clock from './components-lucasrees_com/activity_clock';
import scroll_links from './components-lucasrees_com/scroll_links';

import WordShuffle from './components-lucasrees_com/word_shuffle_optimized';
import experience_icons from './components-lucasrees_com/experience_icons';
import encode_email from './components-lucasrees_com/encode_email';
import accordions from './components-lucasrees_com/accordion';
import external_links from './components-lucasrees_com/external_links';

(() => {
    if ('ontouchstart' in document.documentElement) {
        document.body.classList.add('is-touch')
    }
    const { userAgent } = window.navigator;
    if (userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0) {
        document.body.classList.add('is-ie')
    }
})

document.addEventListener('DOMContentLoaded',(event) =>{
    let nav_querry = window.matchMedia("(min-width: 900px)")
    new vh_fix()
    /* Create Noise */
    const canvas = document.querySelector("#robert_irwin .noise")
    const ctx = canvas.getContext("2d")
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    let lastTime = performance.now();
    const interval = 60000; // 1 minute in milliseconds
    function throttledAnimationFrame(currentTime) {
        if (currentTime - lastTime >= interval) {
            // console.log("Running animation at:", new Date().toLocaleTimeString());
            generateNoise()
            lastTime = currentTime; // Reset the timer
        }
        requestAnimationFrame(throttledAnimationFrame);
    }
    function generateNoise() {
        /* static noise */
        const imageData = ctx.createImageData(canvas.width, canvas.height)
        const data = imageData.data
        for (let i = 0; i < data.length; i += 4) {
            data[i] = Math.random() * 255     // Red
            data[i + 1] = Math.random() * 255 // Green
            data[i + 2] = Math.random() * 255 // Blue
            data[i + 3] = 255                 // Alpha (fully opaque)
        }
        ctx.putImageData(imageData, 0, 0)
        if( !nav_querry ){
            /* moving noise by regenerating */
            requestAnimationFrame(generateNoise)
        }else{
            /* repaint noise every minute once instead */
            requestAnimationFrame(throttledAnimationFrame);
        }
    }
    generateNoise()
    /* */
    new scrolling( gsap, nav_querry )
    new scrolltrigger( ScrollTrigger, nav_querry )
    new scroll_links( gsap )
    /* color effect on intro */
    const classList = ["yellow", "orange", "blue", "orange", "red", "pink"];
    document.querySelectorAll("#intro svg g").forEach(element => {
        element.addEventListener("mouseenter", () => {
            element.classList.remove(...element.classList);
            const randomClass = classList[Math.floor(Math.random() * classList.length)];
            element.classList.add(randomClass);
        });
    });
    /* */
    new interactive_title()
    new experience_icons( gsap )
}, false);

document.fonts.onloadingdone = () => {
    document.querySelector("body").classList.add("fontsready")
};

window.addEventListener('load', (event) => { 
    let nav_querry = window.matchMedia("(min-width: 900px)")
    document.querySelector("#robert_irwin .noise").classList.add('running')
    new encode_email()
    new external_links()
    new paper_canvas( nav_querry )
    document.querySelector("#yayoi_kusama canvas").classList.add('running')
    new WordShuffle( ScrollTrigger )
    new accordions( gsap, ScrollTrigger )
    new activity_clock()
});