// eslint-disable-next-line no-unused-vars

// polyfills
import './polyfills/forEach' // IE fix forEach-function
import './polyfills/scrollingElement' // (?) fix scrolling
import vh_fix from './polyfills/vh_fix' // Smartphone fix 100vh

// gsap
import { gsap } from "gsap"; 
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(ScrollToPlugin, Draggable);

// components
import accordions from './components-lucasrees_com/accordion';

(() => {

    if ('ontouchstart' in document.documentElement) {
        document.body.classList.add('is-touch')
    }

    const { userAgent } = window.navigator;
    if (userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0) {
        document.body.classList.add('is-ie')
    }

})

document.addEventListener('DOMContentLoaded',(event) =>{

    new vh_fix()

    // interactive title

    let regular_title;
    const away_title = "👀 Peek-A-Boo!";
    let is_away = false;

    function add_away_message(){
        is_away = true;
        regular_title = document.title;
        document.title = away_title;
    };

    window.addEventListener('blur',() =>{
        add_away_message();
    });

    function remove_away_message(){
        is_away = false;
        if( document.title == away_title ){
            document.title = regular_title;
        };
    };

    window.addEventListener('focus',() =>{
        remove_away_message();
    });

    // activity timer

    var timer;
    var timerStart;
    var timeSpentOnSite = getTimeSpentOnSite();
    var timeSpent;

    function getTimeSpentOnSite(){
        timeSpentOnSite = parseInt(localStorage.getItem('timeSpentOnSite'));
        timeSpentOnSite = isNaN(timeSpentOnSite) ? 0 : timeSpentOnSite;
        return timeSpentOnSite;
    }
    
    function startCounting(){
        timerStart = Date.now();
        timer = setInterval(function(){
            timeSpentOnSite = getTimeSpentOnSite() + (Date.now() - timerStart);
            localStorage.setItem('timeSpentOnSite',timeSpentOnSite);
            timerStart = parseInt(Date.now());
            if(parseInt(timeSpentOnSite / 1000) < 300){ 
                timeSpent = parseInt(timeSpentOnSite / 1000) + 'seconds';
            }else{
                timeSpent = parseInt((timeSpentOnSite / 1000) / 60) + 'minutes';
            };
            document.getElementById("timer").innerHTML = timeSpent;
            document.getElementById("timer_message").classList.remove('hidden');
        },1000);
    }

    startCounting();
    var stopCountingWhenWindowIsInactive = true;
    if( stopCountingWhenWindowIsInactive ){
        if( typeof document.hidden !== "undefined" ){
            var hidden = "hidden",
            visibilityChange = "visibilitychange",
            visibilityState = "visibilityState";
        }else if ( typeof document.msHidden !== "undefined" ){
            var hidden = "msHidden",
            visibilityChange = "msvisibilitychange",
            visibilityState = "msVisibilityState";
        }
        var documentIsHidden = document[hidden];
        document.addEventListener(visibilityChange, function() {
            if(documentIsHidden != document[hidden]) {
                if( document[hidden] ){ clearInterval(timer); }
                else{ startCounting(); }
                documentIsHidden = document[hidden];
            }
        });
    }

    // randomize experience icons

    function randomizeIcn(icn, step, minOpacity){
        switch(true) {
            case icn.classList.contains("icn_team"):
                let team_paths = icn.querySelectorAll('path');
                let team_opacityValues = [];
                for (let i = 0; i < 10; i++) { team_opacityValues.push(minOpacity + i * step); }
                team_opacityValues.sort(() => Math.random() - 0.5);
                team_paths.forEach((team_path, index) => {
                    team_path.style.opacity = team_opacityValues[index];
                });
                break;
            case icn.classList.contains("icn_teaching"):
                let teaching_paths = icn.querySelectorAll('path');
                let teaching_opacityValues = [];
                for (let i = 0; i < 10; i++) { teaching_opacityValues.push(minOpacity + i * step); }
                teaching_opacityValues.sort(() => Math.random() - 0.5);
                teaching_paths.forEach((teaching_path, index) => {
                    teaching_path.style.opacity = teaching_opacityValues[index];
                });
                break;
            case icn.classList.contains("icn_exploring"):
                function randomCenterOpacity() {
                    return Math.random() * (0.6 - 0.3) + 0.3;
                }
                function randomOuterOpacity() {
                    return Math.random() * (0.3 - 0.1) + 0.1;
                }
                let gradient = document.querySelector('#randomGradient');
                let centerOpacity = randomCenterOpacity();
                let outerOpacity = centerOpacity + randomOuterOpacity();
                gradient.innerHTML = `
                    <stop offset="0%" stop-color="white" stop-opacity="${centerOpacity}" />
                    <stop offset="100%" stop-color="white" stop-opacity="${outerOpacity}" />
                `;
                break;
            case icn.classList.contains("icn_consulting"):
                let consulting_paths = icn.querySelectorAll('path');
                let consulting_opacityValues = [];
                let consulting_step = 0.1;
                let consulting_minOpacity = 0.5;
                for (let i = 0; i < 5; i++) { consulting_opacityValues.push(consulting_minOpacity + i * consulting_step); }
                consulting_opacityValues.sort(() => Math.random() - 0.5);
                consulting_paths.forEach((consulting_path, index) => {
                    consulting_path.style.opacity = consulting_opacityValues[index];
                });
                break;
            case icn.classList.contains("icn_art"):
                let art_paths = icn.querySelectorAll('path');
                let art_opacityValues = [];
                for (let i = 0; i < 10; i++) { art_opacityValues.push(minOpacity + i * step); }
                art_opacityValues.sort(() => Math.random() - 0.5);
                art_paths.forEach((art_path, index) => {
                    art_path.style.opacity = art_opacityValues[index];
                });
                break;
        }
    }

    document.querySelector(".icn_exploring #mask1 rect").style.width = document.querySelector(".icn_exploring").offsetWidth;
    document.querySelector(".icn_exploring #mask1 rect").style.height = document.querySelector(".icn_exploring").offsetHeight;

    const experiences = document.querySelectorAll(".experience");
    experiences.forEach((experience) => {
        const icn = experience.querySelector(".icon > figure > svg");
        const step = 0.05;
        const minOpacity = 0.45;
        // const maxOpacity = 0.9;
        randomizeIcn(icn, step, minOpacity);
        experience.querySelector(".icon > figure").addEventListener("mouseover", (event) => {
            randomizeIcn(icn, step, minOpacity);
        });
    })

}, false);

document.fonts.onloadingdone = () => {

    // basic fonts-loading
    document.querySelector("body").classList.add("fontsready");

    /*
    // split letters of subline
    const line = document.querySelectorAll("header h2");
    line.forEach((element) => {
        const words = element.textContent.split(/\s+/);
        // Map over each word
        const wrappedWords = words.map((word) => {
            // split the word into letters and wrap each letter in a span
            const wrappedLetters = word.split('').map(letter => `<span class="letter" style="font-variation-settings: 'wght' var(--wght);">${letter}</span>`).join('');
            // wrap the entire word in a span
            return `<span class="word">${wrappedLetters}</span>`;
        }).join(' '); // join the words back with spaces
        // replace the element's HTML with the wrapped content
        element.innerHTML = wrappedWords;
    })

    document.querySelectorAll("header h2 .letter").forEach((element) => {
        element.addEventListener("mouseenter", (event) => {
            // max 900
            gsap.to(element, { '--wght': 900, })
            // before after 1 -> 800
            let element_1 = element.previousElementSibling;
            let element_a = element.nextElementSibling;
            gsap.to(element_1, { '--wght': 800, });
            gsap.to(element_a, { '--wght': 800, });
            // before after 2 -> 700
            let element_2 = element_1.previousElementSibling;
            let element_b = element_a.nextElementSibling;
            gsap.to(element_2, { '--wght': 700, });
            gsap.to(element_b, { '--wght': 700, });
            // before after 3 -> 600
            let element_3 = element_2.previousElementSibling;
            let element_c = element_b.nextElementSibling;
            gsap.to(element_3, { '--wght': 600, });
            gsap.to(element_c, { '--wght': 600, });
            // before after 4 -> 500
            let element_4 = element_3.previousElementSibling;
            let element_d = element_c.nextElementSibling;
            gsap.to(element_4, { '--wght': 500, });
            gsap.to(element_d, { '--wght': 500, });
        });
    })

    document.querySelectorAll("header h2 .word").forEach((element) => {
        element.addEventListener("mouseleave", (event) => {
            element.querySelectorAll(".letter").forEach((element) => {
                // reset
                gsap.to(element, { '--wght': 469, })
            })
        })
    })
    */

};

window.addEventListener('load', (event) => { 

    // encode email

    var encEmail = "aGVsbG9AbHVjYXNkaWR0aGlzLmNvbQ==";
    const form = document.getElementById("contact");
    form.setAttribute("title", "send Email");
    form.setAttribute("href", "mailto:".concat(atob(encEmail)));

    /*
    let encEmail = "aGVsbG9AbHVjYXNkaWR0aGlzLmNvbQ=="
    const forms = document.querySelectorAll('a[href="#mailme"]')
    forms.forEach(function (form) {
        form.setAttribute("title", "Email senden")
        form.setAttribute("href", "mailto:".concat(atob(encEmail)))
    })
    */

    new accordions( gsap, ScrollTrigger )

});