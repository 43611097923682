class external_links {
    constructor(){

        const externalLinks = document.querySelectorAll('main a[target="_blank"]');

        const svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"><path d="M16.6672 8.33341H13.6672C11.8003 8.33341 10.8662 8.33341 10.1532 8.69673C9.52595 9.0163 9.01638 9.52586 8.69681 10.1531C8.3335 10.8661 8.3335 11.8002 8.3335 13.6671V26.3337C8.3335 28.2006 8.3335 29.1336 8.69681 29.8466C9.01638 30.4737 9.52595 30.9842 10.1532 31.3037C10.8655 31.6667 11.7985 31.6667 13.6617 31.6667H26.3387C28.2018 31.6667 29.1335 31.6667 29.8458 31.3037C30.473 30.9842 30.9843 30.4732 31.3038 29.8461C31.6668 29.1337 31.6668 28.2017 31.6668 26.3386V23.3334" stroke="black" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"></path><path d="M33.3335 15.0001V6.66675H25.0002M33.3335 6.66675L21.6668 18.3334" stroke="black" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"></path><path d="M33.3335 15.0001V6.66675H25.0002M33.3335 6.66675L21.6668 18.3334" stroke="black" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"></path></svg>';

        externalLinks.forEach(link => {
            
            let link_inner = link.innerHTML
            link.innerHTML = '<span class="icn_external">'+svgIcon+'</span>'+link_inner
            link.classList.add('inlineSVG')

        })

    }
}

export default external_links